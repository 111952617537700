var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dropdown",
    {
      attrs: { "show-timeout": 0, placement: "bottom" },
      on: { command: _vm.handleSetLanguage },
    },
    [
      _c("div", { staticStyle: { padding: "12px" } }, [
        _c("em", { staticClass: "iconfont icon-translate" }),
      ]),
      _c(
        "el-dropdown-menu",
        { attrs: { slot: "dropdown" }, slot: "dropdown" },
        [
          _c(
            "el-dropdown-item",
            { attrs: { disabled: _vm.$i18n.locale === "cn", command: "cn" } },
            [_vm._v(" 简体中文 ")]
          ),
          _c(
            "el-dropdown-item",
            { attrs: { disabled: _vm.$i18n.locale === "en", command: "en" } },
            [_vm._v(" English ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }